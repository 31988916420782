let canBack = false

export default defineNuxtRouteMiddleware((to, from) => {
  const name = (from.name as string)

  if (name.includes('blog-category___') || name.includes('blog-category-blog-blogId___') || name.includes('blog-search___')) {
    if (canBack) {
      return true
    }
    else {
      canBack = true
      return from.fullPath
    }
  }
  else {
    canBack = true
  }

  return true
})
